import React, { useEffect, useState } from "react";
import { Button, Input, Select, useNotification } from "../../../components/UI";
import { useTerminalSetupStore } from "../../../stores";
import { TDisplayOrientation } from "../../../types/terminalSetup";
import styles from "./styles.module.scss";
import { IOption } from "../../../components/UI/Select/Select";
import { useTranslation } from "i18nano";


interface IOrientationOptions {
    id: TDisplayOrientation;
    name: string;
}

const DisplaySetup = () => {
    const t = useTranslation();

    const { setDisaplySetup, terminal, successMessage } = useTerminalSetupStore(state => state);
    const { addNotific } = useNotification();

    const opt: IOrientationOptions[] = [
        {
            id: "horizontal",
            name: t("terminal_setup_page.display_setup.orientation.horizontal")
        },
        {
            id: "horizontal_reverse",
            name: t("terminal_setup_page.display_setup.orientation.horizontal_reverse")
        },
        {
            id: "vertical",
            name: t("terminal_setup_page.display_setup.orientation.vertical")
        },
        {
            id: "vertical_reverse",
            name: t("terminal_setup_page.display_setup.orientation.vertical_reverse")
        }
    ];
    const [resolutionOptions, setResolutionOptions] = useState<IOption[]>([]);

    const [orientation, setOrientation] = useState<TDisplayOrientation>(terminal?.display_setup ? terminal.display_setup.orientation : "horizontal");
    const [resolution, setResolution] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [readonly, setReadonly] = useState(true);

    useEffect(() => {
        if (resolution !== "custom") {
            setWidth(resolution.split("x")[0]);
            setHeight(resolution.split("x")[1]);
            setReadonly(true);
        } else {
            setReadonly(false);
        }
    }, [resolution]);

    useEffect(() => {
        if (successMessage) {
            addNotific({ ...successMessage, type: "success" });
        }
    }, [successMessage]);

    useEffect(() => {
        if (!terminal || !terminal.display_setup) {
            return;
        }

        if (!terminal.display_setup.resolution_variants) {
            setResolution("custom");
            setResolutionOptions([{
                id: "custom",
                name: "custom"
            }]);
            return;
        }

        const data = terminal.display_setup.resolution_variants.map(item => ({
            id: item,
            name: item
        }));

        data.push({
            id: "custom",
            name: "custom"
        });

        if (terminal.display_setup.resolution_variants.includes(terminal.display_setup.resolution)) {
            setResolution(terminal.display_setup.resolution);
        } else {
            setResolution("custom");
            setWidth(terminal.display_setup.resolution.split("x")[0]);
            setHeight(terminal.display_setup.resolution.split("x")[1]);
        }

        setResolutionOptions(data);
    }, [terminal]);

    const setDisplaySetupHandler = () => {
        if (!terminal) {
            return;
        }

        const data = {
            terminal_id: terminal.id,
            resolution,
            orientation
        };

        if (resolution === "custom") {
            data.resolution = `${width}x${height}`;
        }

        setDisaplySetup(data);
    };

    if (!terminal) {
        return null;
    }

    if (!terminal.display_setup) {
        return (
            <div className={styles.root}>
                <h1>{t("terminal_setup_page.display_setup.not_connected")}</h1>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.main_container}>
                <h2>{t("terminal_setup_page.display_setup.title")}</h2>
                <h3>{t("terminal_setup_page.display_setup.subtitle")}</h3>
                <div style={{ width: 250 }}>
                    <Select options={opt} value={orientation} setValue={setOrientation} />
                </div>
                <h3>{t("terminal_setup_page.display_setup.subtitle2")}</h3>
                <div>
                    <div className={styles.resolutions_container} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: 250 }}>
                            <Select options={resolutionOptions} value={resolution} setValue={setResolution} />
                        </div>
                        <span>or</span>
                        <div style={{ width: 250 }}>
                            <Input type="number" value={width} onChange={setWidth} readonly={readonly} />
                        </div>
                        <div style={{ width: 250 }}>
                            <Input type="number" value={height} onChange={setHeight} readonly={readonly} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.button_container}>
                <Button variant={"primary"} onClick={setDisplaySetupHandler}>{t("terminal_setup_page.display_setup.button")}</Button>
            </div>
        </div>
    );
};

export default DisplaySetup;