import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Button, ModalConfirm } from "../../../../components/UI";
import { useTerminalStore } from "../../../../stores";
import { ITerminal } from "../../../../types/terminals";
import { TPlaylistModal } from "../TerminalItem";
import styles from "./styles.module.scss";

interface IProps {
    terminal: ITerminal;
    showModalHandler: (mode: TPlaylistModal) => void;
}

const TerminalSettingsModal: React.FC<IProps> = ({ terminal, showModalHandler }) => {
    const t = useTranslation();
    const { unbindPlaylist, updatePlaylist } = useTerminalStore(state => state);

    const [showConfirm, setShowConfirm] = useState(false);

    const unbindPlaylistHandler = () => {
        if (terminal.binding_playlist) {
            unbindPlaylist([terminal.id]);
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.message}>
                {t("terminal_page.terminal_settings_modal.message", { name: terminal.name })}
            </div>
            <div className={styles.buttons_container}>
                <Button variant={!terminal.terminal_pool_name ? "primary-empty" : "disabled"} onClick={() => showModalHandler("bind")}>{t("terminal_page.terminal_settings_modal.controls.change_playlist")}</Button>
                <Button variant={terminal.binding_playlist ? "primary-empty" : "disabled"} onClick={() => window.location.href = `/playlists/edit/${terminal.binding_playlist!.playlist_id}`}>{t("terminal_page.terminal_settings_modal.controls.view_playlist")}</Button>
                <Button variant={terminal.binding_playlist && !terminal.terminal_pool_name ? "primary-empty" : "disabled"} onClick={() => updatePlaylist(terminal.id)}>{t("terminal_page.terminal_settings_modal.controls.update_playlist")}</Button>
                <Button variant={terminal.binding_playlist && !terminal.terminal_pool_name ? "danger-empty" : "disabled"} onClick={() => setShowConfirm(true)}>{t("terminal_page.terminal_settings_modal.controls.unbind_playlist")}</Button>
            </div>
            {terminal.binding_playlist
                ? <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={unbindPlaylistHandler} onCancel={() => { }}>
                    {t("terminal_page.unbind_confirm.message", { name: terminal.binding_playlist.playlist_name })}
                </ModalConfirm>
                : null
            }
        </div>
    );
};

export default TerminalSettingsModal;