import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { IPlaylistStore } from "../../types/playlist";
import { defaultState, defaultFilters } from "../consts";
import { deletePlaylistApi, getAllPlaylistApi } from "../../api/playlist-api";


export const usePlaylistStore = create<IPlaylistStore>()(
    immer(
        (set, get) => ({
            playlistList: defaultState,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.playlistList = defaultState;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchPlaylist: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getAllPlaylistApi(filters).then((data) => {
                    set((state) => {
                        state.playlistList = { ...state.playlistList, ...data };
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set((state) => {
                        state.loading = false;
                    });
                });
            },
            fetchPlaylistsForBinding: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getAllPlaylistApi(filters).then((data) => {
                    set((state) => {
                        if (data) {
                            state.playlistList.data.push(...data.data);
                            state.playlistList.page = data.page;
                            state.playlistList.per_page = data.per_page;
                            state.playlistList.count = data.count;
                        }
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set((state) => {
                        state.loading = false;
                    });
                });
            },
            deletePlaylist: async (playlist_ids) => {
                const { fetchPlaylist } = get();

                await deletePlaylistApi(playlist_ids).then(() => {
                    fetchPlaylist(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.delete"
                        };
                    });
                });
            },
            selectPlaylist: (selectStatus) => {
                set((state) => {
                    state.playlistList.data = [...state.playlistList.data.map(playlist => {
                        if (selectStatus.selectedData === "all" || selectStatus.selectedData === playlist.id) {
                            return { ...playlist, isChecked: selectStatus.selectState };
                        }
                        return playlist;
                    })];
                });
            },
        })
    )
);
