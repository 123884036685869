import { useTranslation, useTranslationChange } from "i18nano";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { rtlLangs } from "../../assets/locale/index";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { ExpandableWrapperHeight, IconArrowDown, IconLogOut, IconUser, ModalConfirm } from "../UI";
import { useUserStore } from "../../stores";


// const languages: Record<string, string> = {
//     "ru": "Russian",
//     "en": "English",
//     "ar": "العربية",
// };

const Navbar = () => {
    const { logOut, user } = useUserStore(state => state);
    const translation = useTranslationChange();
    const t = useTranslation();

    // const [langExpanded, setLandExpanded] = useState(false);

    const [userExpanded, setUserExpanded] = useState(false);
    const [confirmLogoutModal, setConfirmLogoutModal] = useState(false);

    const logo = require("../../assets/images/logo.png");

    // useEffect(() => {
    //     localStorage.setItem("lang", translation.lang);

    //     return () => {
    //         window.location.reload();
    //     };
    // }, [translation.lang]);

    return (
        <nav className={styles.navbar} dir={"ltr"}>
            <div className={styles.navbar_logo}>
                <img src={logo} alt="" />
            </div>
            {user
                ? <div className={styles.nav_menu} dir={rtlLangs.includes(translation.lang) ? "rtl" : "ltr"}>
                    <ul className={styles.nav_list}>
                        <li className={styles.nav_item}><NavLink to={"/content"} className={({ isActive }) => classnames(styles.nav_link, { [styles.active]: isActive })}>
                            {t("content.many")}
                        </NavLink></li>
                        <li className={styles.nav_item}><NavLink to={"/scenarios"} className={({ isActive }) => classnames(styles.nav_link, { [styles.active]: isActive })}>
                            {t("scenario.many")}
                        </NavLink></li>
                        <li className={styles.nav_item}><NavLink to={"/playlists"} className={({ isActive }) => classnames(styles.nav_link, { [styles.active]: isActive })}>
                            {t("playlist.many")}
                        </NavLink></li>
                        <li className={styles.nav_item}><NavLink to={"/terminals"} className={({ isActive }) => classnames(styles.nav_link, { [styles.active]: isActive })}>
                            {t("terminal.many")}
                        </NavLink></li>
                        <li className={styles.nav_item}><NavLink to={"/terminal-pools"} className={({ isActive }) => classnames(styles.nav_link, { [styles.active]: isActive })}>
                            {t("terminal_pool.many")}
                        </NavLink></li>
                        <li className={styles.nav_item}><NavLink to={"/analytics"} className={({ isActive }) => classnames(styles.nav_link, { [styles.active]: isActive })}>
                            {t("analytics.many")}
                        </NavLink></li>
                        {/*
                        <li className={styles.nav_item} onMouseEnter={() => setLandExpanded(true)} onMouseLeave={() => setLandExpanded(false)}>
                            <div className={classnames(styles.lang, { [styles.active]: langExpanded })}>
                                Languages
                            </div>
                            <div className={styles.dropdown_container}>
                                <ul className={styles.dropdown_list}>
                                    {translation.all.map((lang) => (
                                        <ExpandableWrapperHeight isExpanded={langExpanded} key={lang}>
                                            <li onClick={() => translation.change(lang)}>
                                                {languages[lang]}
                                            </li>
                                        </ExpandableWrapperHeight>
                                    ))}
                                </ul>
                            </div>
                        </li> */}

                    </ul>
                    <div className={styles.user_container}>
                        <div className={styles.user_name} onClick={() => setUserExpanded(!userExpanded)} >
                            <IconUser color={userExpanded ? "blue" : "black"} size={20} />
                            <span>{user.name}</span>
                            <span className={classnames(styles.arrow_container, { [styles.active]: userExpanded })}>
                                <IconArrowDown color={userExpanded ? "blue" : "default"} size={16} />
                            </span>
                        </div>
                        <div className={classnames(styles.user_menu, { [styles.active]: userExpanded })}>
                            <ExpandableWrapperHeight isExpanded={userExpanded}>
                                <span className={styles.user_menu_item} onClick={() => {
                                    setConfirmLogoutModal(true);
                                    setUserExpanded(false);
                                }}>
                                    <IconLogOut size={20} />
                                    <span className={styles.item_text}>
                                        {t("button.logout")}
                                    </span>
                                </span>
                            </ExpandableWrapperHeight>
                        </div>
                    </div>
                </div>
                : null
            }
            <ModalConfirm isShow={confirmLogoutModal} setIsShow={setConfirmLogoutModal} onConfirm={logOut} onCancel={() => { }}>
                {t("confirm.logout")}
            </ModalConfirm>
        </nav>
    );
};

export default Navbar;