import classnames from "classnames";
import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconEdit, IconSettings, Modal, Tooltip } from "../../../components/UI";
import { ITerminal } from "../../../types/terminals";
import BindingPlaylistModal from "./BindingPlaylistModal";
import styles from "./styles.module.scss";
import TerminalSettingsModal from "./TerminalSettingsModal";

interface IProps {
    terminal: ITerminal
}

export type TPlaylistModal = "bind" | "selectMode"

const TerminalItem: React.FC<IProps> = ({ terminal }) => {
    const t = useTranslation();

    const [showModalPlylsits, setShowModalPlaylists] = useState(false);
    const [playlistModal, setPlaylistModal] = useState<React.ReactNode>();
    const [modalTitle, setModalTitle] = useState({
        title: "",
        subtitle: ""
    });

    const showModalHandler = (mode: TPlaylistModal) => {
        switch (mode) {
            case "bind": {
                setPlaylistModal(<BindingPlaylistModal terminalId={terminal.id} setShowModal={setShowModalPlaylists} />);
                setModalTitle({ title: t("terminal_page.bind_playlist_modal.title"), subtitle: t("terminal_page.bind_playlist_modal.subtitle") });
                break;
            }
            case "selectMode": {
                setPlaylistModal(<TerminalSettingsModal terminal={terminal} showModalHandler={showModalHandler} />);
                setModalTitle({ title: t("terminal_page.terminal_settings_modal.title"), subtitle: t("terminal_page.terminal_settings_modal.subtitle") });
                break;
            }
        }

        setShowModalPlaylists(true);
    };

    return (
        <div className="table-item cols-4">
            <div></div>
            <div className={"preview-container"}>
                <Tooltip text={terminal.server_connected ? t("terminal_page.server_connected") : t("terminal_page.server_disconnected")}>
                    <div className={classnames(styles.server_connect_status, { [styles.active]: terminal.server_connected })}></div>
                </Tooltip>
                <div>{terminal.name}</div>
            </div>
            <div className={styles.current_playlist_container}>
                {terminal.binding_playlist
                    ? <Link to={`/playlists/edit/${terminal.binding_playlist.playlist_id}`}>
                        <span>{terminal.binding_playlist.playlist_name}</span>
                        <IconEdit color="blue" size={15} />
                    </Link>
                    : <div>
                        {t("terminal_page.no_binded_playlist")}
                    </div>
                }
            </div>
            <div>{terminal.terminal_pool_name ? terminal.terminal_pool_name : "No terminal pool"}</div>
            <div className={"settings-container"}>
                <Tooltip text={t("actions.settings")}>
                    <span onClick={() => showModalHandler("selectMode")}><IconEdit size={25} color={"blue"} /></span>
                </Tooltip>
                {terminal.server_connected
                    ? <Tooltip text={t("actions.hardware_settings")}>
                        <Link to={`/terminals/setup/${terminal.id}`}>
                            <IconSettings size={25} color={"blue"} />
                        </Link>
                    </Tooltip>
                    : <Tooltip text={t("terminal_page.connect_to_server")}>
                        <IconSettings size={25} color={"default"} />
                    </Tooltip>
                }
            </div>
            <Modal isShow={showModalPlylsits} setIsShow={setShowModalPlaylists} {...modalTitle}>
                {playlistModal}
            </Modal>
        </div>
    );
};

export default TerminalItem;