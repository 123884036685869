import React, { useEffect, useState } from "react";
import { useTranslation } from "i18nano";
import ControlPanel from "../../components/ControlPanel";
import PageContainer from "../../components/PageContainer";
import SortPanelContainer from "../../components/SortPanelContainer";
import SortPanelItem from "../../components/SortPanelContainer/SortPanelItem";
import { Loader, Pagination, useNotification } from "../../components/UI";
import { useTerminalStore } from "../../stores";
import { IFilters } from "../../types/default";
import TerminalItem from "./TerminalItem";


const TerminlsPage = () => {
    const { loading, terminalList, fetchTerminals, clearStore, error } = useTerminalStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const [filters, setFilters] = useState<IFilters>({
        page: 1,
        perPage: localStorage.getItem("perPage") ? Number(localStorage.getItem("perPage")) : 10,
        filters: { name: "" },
        orderBy: "name",
        orderDesk: false
    });    

    const filtersContent = (order_by: string) => {
        if (order_by === filters.orderBy) {
            setFilters(prev => ({ ...prev, orderDesk: !filters.orderDesk }));
        }
        setFilters(prev => ({ ...prev, orderBy: order_by }));
    };

    useEffect(() => {
        return () => clearStore();
    }, []);

    useEffect(() => {
        fetchTerminals(filters);
    }, [filters]);

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("content.genitive") }),
                type: "danger"
            });
        }
    }, [error]);

    return (
        <PageContainer>
            <ControlPanel
                onSearch={(val) => setFilters(prev => ({ ...prev, filters: { name: val } }))}
            />
            <SortPanelContainer>
                <div></div>
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.name")}
                    onClick={() => filtersContent("name")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.current_playlist")}
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.terminal_pool")}
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.actions")}
                />
            </SortPanelContainer>
            {loading
                ? <h1 style={{
                    "textAlign": "center",
                    "fontSize": "50px",
                    "marginTop": "100px"
                }}><Loader /></h1>
                : <>
                    {terminalList.data.length
                        ? <>
                            <div className={"content-table"}>
                                {terminalList.data.map((terminal) =>
                                    <TerminalItem key={terminal.id} terminal={terminal}/>
                                )}
                            </div>
                            <Pagination
                                perPage={filters.perPage}
                                setPerPage={(val) => setFilters(prev => ({ ...prev, perPage: val }))}
                                page={filters.page}
                                setPage={(val) => setFilters(prev => ({ ...prev, page: val }))}
                                count={terminalList.count}
                            />
                        </>
                        : <h1 style={{
                            "textAlign": "center",
                            "fontSize": "50px",
                            "marginTop": "100px"
                        }}>{t("missing.content")}</h1>
                    }
                </>
            }
        </PageContainer>
    );
};

export default TerminlsPage;
