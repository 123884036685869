import React, { useEffect, useState } from "react";
import { useTranslation } from "i18nano";
import ControlPanel from "../../components/ControlPanel";
import PageContainer from "../../components/PageContainer";
import SortPanelContainer from "../../components/SortPanelContainer";
import SortPanelItem from "../../components/SortPanelContainer/SortPanelItem";
import { Loader, Pagination, useNotification, ModalConfirm, Button, Modal } from "../../components/UI";
import { useTerminalPoolStore } from "../../stores";
import { IFilters } from "../../types/default";
import TerminalPoolItem from "./TerminalPoolItem";
import TerminalPoolSetup from "./TerminalPoolSetup";


const TerminlPoolsPage = () => {
    const { loading, terminalPoolsList, error, fetchTerminalPools, clearStore, selectTerminalPool, deleteTerminalPools } = useTerminalPoolStore(state => state);
    const { addNotific } = useNotification();
    const user_role = localStorage.getItem("user_role");
    const t = useTranslation();

    const [showSetupModal, setShowSetupModal] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [filters, setFilters] = useState<IFilters>({
        page: 1,
        perPage: localStorage.getItem("perPage") ? Number(localStorage.getItem("perPage")) : 10,
        filters: { name: "" },
        orderBy: "name",
        orderDesk: false
    });    

    const filtersContent = (order_by: string) => {
        if (order_by === filters.orderBy) {
            setFilters(prev => ({ ...prev, orderDesk: !filters.orderDesk }));
        }
        setFilters(prev => ({ ...prev, orderBy: order_by }));
    };

    useEffect(() => {
        return () => clearStore();
    }, []);

    useEffect(() => {
        fetchTerminalPools(filters);
    }, [filters]);

    const selectAll = (checkedState: boolean) => {
        selectTerminalPool({ selectState: checkedState, selectedData: "all" });
        setCheckedAll(checkedState);
    };

    const onDelete = () => {
        const deleteList: Array<string> = [];
        for (const file of terminalPoolsList.data) {
            if (file.isChecked) {
                deleteList.push(file.id);
            }
        }
        deleteTerminalPools(deleteList);
        selectTerminalPool({ selectState: false, selectedData: "all" });
        setCheckedAll(false);
    };


    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("content.genitive") }),
                type: "danger"
            });
        }
    }, [error]);


    return (
        <PageContainer>
            <ControlPanel
                onSelectAll={selectAll}
                onDelete={() => setShowConfirm(true)}
                onSearch={(val) => setFilters(prev => ({ ...prev, filters: { name: val } }))}
                selectedLenght={terminalPoolsList.data.filter(item => item.isChecked).length}
                righrPanel={
                    user_role === "admin" || user_role === "organization_admin" ? 
                        <Button variant={"primary"} onClick={() => setShowSetupModal(true)}>{t("terminal_pool_page.add_new")}</Button>
                    : undefined
                }
            />
            <SortPanelContainer>
                <SortPanelItem
                    type={"checkbox"}
                    onChange={(e) => selectAll(e.target.checked)}
                    checked={checkedAll}
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.name")}
                    onClick={() => filtersContent("name")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.current_playlist")}
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.actions")}
                />
            </SortPanelContainer>
            {loading
                ? <h1 style={{
                    "textAlign": "center",
                    "fontSize": "50px",
                    "marginTop": "100px"
                }}><Loader /></h1>
                : <>
                    {terminalPoolsList.data.length
                        ? <>
                            <div className={"content-table"}>
                                {terminalPoolsList.data.map((terminalPool) =>
                                    <TerminalPoolItem key={terminalPool.id} terminalPool={terminalPool}/>
                                )}
                            </div>
                            <Pagination
                                perPage={filters.perPage}
                                setPerPage={(val) => setFilters(prev => ({ ...prev, perPage: val }))}
                                page={filters.page}
                                setPage={(val) => setFilters(prev => ({ ...prev, page: val }))}
                                count={terminalPoolsList.count}
                            />
                        </>
                        : <h1 style={{
                            "textAlign": "center",
                            "fontSize": "50px",
                            "marginTop": "100px"
                        }}>{t("missing.terminalPool")}</h1>
                    }
                </>
            }
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => onDelete()} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: `${t("elements")} (${terminalPoolsList.data.filter(item => item.isChecked).length})` })}</p>
            </ModalConfirm>
            <Modal isShow={showSetupModal} setIsShow={setShowSetupModal} title="Add new terminal pool" disableOverlay={true}>
                <TerminalPoolSetup setIsShow={setShowSetupModal} />
            </Modal>
        </PageContainer>
    );
};

export default TerminlPoolsPage;
