import { useTranslation } from "i18nano";
import React, { useEffect, useState } from "react";
import { Button, IconPlaylistFile, IconSearch, SearchInput, useNotification } from "../../../../components/UI";
import { usePlaylistStore, useTerminalStore } from "../../../../stores";
import styles from "./styles.module.scss";


interface IProps {
    terminalId: string;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BindingPlaylistModal: React.FC<IProps> = ({ terminalId, setShowModal }) => {
    const { bindPlaylist } = useTerminalStore(state => state);
    const { fetchPlaylistsForBinding, clearStore, playlistList, error } = usePlaylistStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const perPage: number = 100;
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [selectedPlaylist, setSelectedPlaylist] = useState<string>();

    const bindPlaylistHelper = () => {
        if (selectedPlaylist) {
            bindPlaylist({
                terminal_id: terminalId,
                playlist_id: selectedPlaylist
            });
        }
    };

    useEffect(() => {
        clearStore();
        setSelectedPlaylist("")
        fetchPlaylistsHelper(1)
    }, [searchText]);

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("content.genitive") }),
                type: "danger"
            });
        }
    }, [error]);

    const fetchPlaylistsHelper = (page: number) => {
        setPage(page);

        fetchPlaylistsForBinding({
            page: page,
            perPage: perPage,
            filters: { name: searchText },
            orderBy: "name",
            orderDesk: false
        });
    }

    return (
        <div className={styles.root}>
            <div className={styles.search_container}>
                <SearchInput
                    placeholder={t("placeholder.search")}
                    iconEnd={<IconSearch color={"soft"} />}
                    onSearch={(val) => setSearchText(val)}
                />
            </div>
            <div className={styles.bind_playlist_items_container}>
                {playlistList.data.map(item =>
                    <label className={styles.bind_item_container} key={item.id} htmlFor={`checkbox-${item.id}`}>
                        <div className={styles.preview_container}>
                            <IconPlaylistFile size={40} color={"black"} />
                        </div>
                        <div className={styles.name_container}>
                            <div>
                                {item.name}
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    name="bind-playlist-radio"
                                    id={`checkbox-${item.id}`}
                                    onClick={() => setSelectedPlaylist(item.id)}
                                />
                            </div>
                        </div>
                    </label>
                )}
                <div className={styles.show_more}>
                    {playlistList.data.length < playlistList.count ? <span onClick={() => fetchPlaylistsHelper(page + 1)}>{t("create_playlist.add_playlist_item.show_more")}</span> : null}
                </div>
            </div>
            <div className={styles.footer_container}>
                <div className={styles.buttons_container}>
                    <Button onClick={() => setShowModal(false)} variant={"secondary"}>{t("button.cancel")}</Button>
                    <Button onClick={bindPlaylistHelper} variant={selectedPlaylist ? "primary" : "disabled"}>{t("button.apply")}</Button>
                </div>
            </div>
        </div>
    );
};

export default BindingPlaylistModal;