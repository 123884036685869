import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ITerminalPoolStore } from "../../types/terminalPool";
import { defaultState, defaultFilters } from "../consts";
import {
    createTerminalPool,
    deleteTerminalPool,
    getTerminalPoolsList,
    changeTerminalPoolPlaylist,
    updateTerminalPool,
} from "../../api/terminal-pool-api";

export const useTerminalPoolStore = create<ITerminalPoolStore>()(
    immer(
        (set, get) => ({
            terminalPoolsList: defaultState,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.terminalPoolsList = defaultState;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchTerminalPools: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getTerminalPoolsList(filters).then(data => {
                    set((state) => {
                        state.terminalPoolsList = { ...state.terminalPoolsList, ...data };
                    });
                }).catch((e: Error) => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            createTerminalPool: async (terminal_pool) => {
                const { fetchTerminalPools } = get();

                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await createTerminalPool(terminal_pool).then(data => {
                    fetchTerminalPools(defaultFilters);
                }).catch((e: Error) => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            updateTerminalPool: async (terminal_pool) => {
                const { fetchTerminalPools } = get();

                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await updateTerminalPool(terminal_pool).then(data => {
                    fetchTerminalPools(defaultFilters);
                }).catch((e: Error) => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            deleteTerminalPools: async (terminal_ids) => {
                const { fetchTerminalPools } = get();

                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await deleteTerminalPool(terminal_ids).then(data => {
                    fetchTerminalPools(defaultFilters);
                }).catch((e: Error) => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            changeTerminalPoolPlaylist: async (data) => {
                const { fetchTerminalPools } = get();

                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await changeTerminalPoolPlaylist(data).then(data => {
                    fetchTerminalPools(defaultFilters);
                }).catch((e: Error) => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            selectTerminalPool: (selectStatus) => {
                set((state) => {
                    state.terminalPoolsList.data = [...state.terminalPoolsList.data.map(terminalPool => {
                        if (selectStatus.selectedData === "all" || selectStatus.selectedData === terminalPool.id) {
                            return { ...terminalPool, isChecked: selectStatus.selectState };
                        }
                        return terminalPool;
                    })];
                });
            }
        })
    )
);