import axios from "./axios";
import { IFilters } from "../types/default";
import { 
    ITerminalPool, 
    ITerminalPoolsList, 
    ICreateTerminalPool, 
    IUpdateTerminalPool, 
    IChangePlaylist,
} from "../types/terminalPool";


export const getTerminalPoolById = async (terminal_pool_id: string): Promise<ITerminalPool | undefined>  => {
    return await axios.get(`/terminal-pool/${terminal_pool_id}/`);
};

export const getTerminalPoolsList = async (filters: IFilters): Promise<ITerminalPoolsList | undefined> => {
    return await axios.post("/terminal-pool/", {
        page: filters.page,
        per_page: filters.perPage,
        filters: filters.filters,
        order_by: filters.orderBy,
        order_desc: filters.orderDesk
    });
};

export const createTerminalPool = async (data: ICreateTerminalPool): Promise<Record<string, string> | undefined> => {
    return await axios.post("/terminal-pool/create/", data);
};

export const updateTerminalPool = async (data: IUpdateTerminalPool) => {
    return await axios.post("/terminal-pool/update/", data);
};

export const deleteTerminalPool = async (data: string[]) => {
    return await axios.delete("/terminal-pool/delete/", { data });
};

export const changeTerminalPoolPlaylist = async (data: IChangePlaylist) => {
    return await axios.post("/terminal-pool/change-playlist/", data);
};
