import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconEdit, IconSettings, Modal, Tooltip, ModalConfirm, IconCloseSquare } from "../../../components/UI";
import { ITerminalPool } from "../../../types/terminalPool";
import BindingPlaylistModal from "./BindingPlaylistModal";
import styles from "./styles.module.scss";
import { useTerminalPoolStore } from "../../../stores";
import TerminalPoolSettingsModal from "./TerminalPoolSettingsModal";
import TerminalPoolSetup from "../TerminalPoolSetup";

interface IProps {
    terminalPool: ITerminalPool
}

export type TPlaylistModal = "edit" | "bind" | "selectMode"

const TerminalPoolItem: React.FC<IProps> = ({ terminalPool }) => {
    const t = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [modal, setModal] = useState<React.ReactNode>();
    const [disableOverlay, setDisableOverlay] = useState(false);
    const [modalTitle, setModalTitle] = useState({
        title: "",
        subtitle: ""
    });
    const { deleteTerminalPools, selectTerminalPool } = useTerminalPoolStore(state => state);
    
    const showModalHandler = (mode: TPlaylistModal) => {
        switch (mode) {
            case "edit": {
                setModal(<TerminalPoolSetup terminalPoolId={terminalPool.id} terminalPoolName={terminalPool.name} setIsShow={setShowModal} />);
                setModalTitle(
                    { 
                        title: `${t("terminal_pool_page.setup_model.title")} "${terminalPool.name}"`, 
                        subtitle: terminalPool.current_playlist ? `${t("terminal_pool_page.setup_model.subtitle")} "${terminalPool.current_playlist?.playlist_name}"`: "" 
                    }
                );
                setDisableOverlay(true);
                break;
            }
            case "bind": {
                setModal(<BindingPlaylistModal terminalPoolId={terminalPool.id} setShowModal={setShowModal} />);
                setModalTitle({ title: t("terminal_pool_page.bind_playlist_modal.title"), subtitle: t("terminal_pool_page.bind_playlist_modal.subtitle") });
                setDisableOverlay(false);
                break;
            }
            case "selectMode": {
                setModal(<TerminalPoolSettingsModal terminalPool={terminalPool} showModalHandler={showModalHandler} />);
                setModalTitle({ title: t("terminal_pool_page.terminal_settings_modal.title"), subtitle: t("terminal_pool_page.terminal_settings_modal.subtitle") });
                setDisableOverlay(false);
                break;
            }
        }

        setShowModal(true);
    };

    return (
        <div className="table-item cols-3">
            <div className={"checkbox-container"}>
                <input
                    type="checkbox"
                    checked={terminalPool.isChecked}
                    onChange={() => selectTerminalPool({ selectState: !terminalPool.isChecked, selectedData: terminalPool.id })}
                />
            </div>
            <div className={"preview-container"}>
                <div>{terminalPool.name}</div>
            </div>
            <div className={styles.current_playlist_container}>
                {terminalPool.current_playlist
                    ? <Link to={`/playlists/edit/${terminalPool.current_playlist.playlist_id}`}>
                        <span>{terminalPool.current_playlist.playlist_name}</span>
                        <IconEdit color="blue" size={15} />
                    </Link>
                    : <div>
                        {t("terminal_pool_page.no_binded_playlist")}
                    </div>
                }
            </div>
            <div className={"settings-container"}>
                <Tooltip text={t("actions.settings")}>
                    <span onClick={() => showModalHandler("selectMode")}><IconEdit size={25} color={"blue"} /></span>
                </Tooltip>
                <Tooltip text={t("actions.delete")}>
                    <span className="action-container" onClick={() => setShowConfirm(true)}><IconCloseSquare color={"red"} size={25} /></span>
                </Tooltip>
            </div>
            <Modal isShow={showModal} setIsShow={setShowModal} disableOverlay={disableOverlay} {...modalTitle}>
                {modal}
            </Modal>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => deleteTerminalPools([terminalPool.id.toString()])} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: terminalPool.name })}</p>
            </ModalConfirm>
        </div>
    );
};

export default TerminalPoolItem;