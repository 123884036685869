import { AxiosError } from "axios";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { signinApi, signoutApi } from "../../api/user-api";
import { IError } from "../../types/default";
import { IUserStore } from "../../types/user";


const defaultUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : undefined;

export const useUserStore = create<IUserStore>()(
    immer(
        (set, get) => ({
            user: defaultUser,
            loading: false,
            error: undefined,
            authentication: async (data) => {
                set(state => {
                    state.loading = true;
                });

                await signinApi(data).then((user) => {
                    if (!user) {
                        set(state => {
                            state.error = {
                                title: "notification.error.label",
                                body: "notification.error.authentication.wrong_login_password"
                            };
                        });

                        return;
                    }

                    set(state => {
                        state.user = user;
                    });

                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("lang", user.lang);
                    localStorage.setItem("user_role", user.role);
                    window.location.reload();
                }).catch((e: AxiosError) => {
                    let error: IError;

                    switch (e.response?.status) {
                        case 401: {
                            error = {
                                title: "notification.error.label",
                                body: "notification.error.authentication.wrong_login_password"
                            };
                            break;
                        }
                        default: {
                            error = {
                                title: "notification.error.label",
                                body: "notification.error.server_connect"
                            };
                            break;
                        }
                    }

                    set(state => {
                        state.error = error;
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            logOut: async () => {
                await signoutApi().then(() => {
                    set(state => {
                        state.user = undefined;
                    });

                    localStorage.removeItem("user");
                    window.location.reload();
                }).catch(() => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                });
            }
        })
    )
);
