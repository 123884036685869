import React, { useEffect, useState } from "react";
import { useScenarioStore, useContentStore, useCreatePlaylistStore } from "../../../../stores";
import { IPlaylistItem } from "../../../../types/playlist";
import "./AddPlaylistItems.scss";
import classnames from "classnames";
import { Button, IconArrowDown, SearchInput, IconSearch, IconArrowUp, IconScenarioFile, IconVideoFile } from "../../../../components/UI";
import { IContent } from "../../../../types/contentList";
import { IScenario } from "../../../../types/scenarioList";
import { useTranslation } from "i18nano";
import { getOneScenarioApi } from "../../../../api/scenario-api";
import { v4 as uuidv4 } from "uuid";
import ContentContainer from "../../../../components/ContentContainer";


type menuSelect = "content" | "scenario";

interface IProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddPlaylistItems: React.FC<IProps> = ({ setShowModal }) => {
    const t = useTranslation();

    const perPage: number = 100;
    const [searchText, setSearchText] = useState("");
    const { addPlaylistItem } = useCreatePlaylistStore(state => state);
    const { fetchContentForPlaylist, clearStore: clearContentStore, contentList } = useContentStore(state => state);
    const { fetchScenarioForPlaylist, clearStore: clearScenarioStore, scenarioList } = useScenarioStore(state => state);

    const [page, setPage] = useState(1);
    const [menuSelect, setMenuSelect] = useState<menuSelect>("content");

    const [contentItems, setContentItems] = useState<IContent[]>([]);
    const [scenarioItems, setScenarioItems] = useState<IScenario[]>([]);
    const [selectedContent, setSelectedContent] = useState<Record<string, boolean>>({});
    const [selectedScenarios, setSelectedScenarios] = useState<Record<string, boolean>>({});

    useEffect(() => {
        clearContentStore();
        clearScenarioStore();
        fetchPlaylistItemsHelper(1)
    }, [searchText]);

    useEffect(() => {
        if (menuSelect === "scenario" && scenarioList.data.length == 0 && contentList.data.length > 0) {
            fetchPlaylistItemsHelper(1)
        }
        
        if (menuSelect === "content" && contentList.data.length == 0 && scenarioList.data.length > 0) {
            fetchPlaylistItemsHelper(1)
        }
    }, [menuSelect]);

    const fetchPlaylistItemsHelper = (page: number) => {
        setPage(page);

        const filters = {
            page: page,
            perPage: perPage,
            filters: { name: searchText },
            orderBy: "name",
            orderDesk: false
        };
        if (menuSelect === "content") {
            fetchContentForPlaylist(filters);
        } else {
            fetchScenarioForPlaylist(filters);
        }
    }

    const getScenario = async (scenarioId: string): Promise<IPlaylistItem | undefined> => {
        const data = await getOneScenarioApi(scenarioId);

        if (!data) {
            return;
        }

        return {
            id: uuidv4(),
            name: data.name,
            content: data.scenario_items,
            content_id: data.id,
            preview: data.preview,
            display_type: "required",
            is_interruptible: false,
            duration: data.duration,
            blackout_duration: 300,
            type: "scenario",
            triggers: {
                cv: [],
                datetime: [],
                eye_contact: null,
                gesture: null
            }
        };
    };

    const addContent = (val: boolean, item: IContent) => {
        if (val) {
            setContentItems(prev => [...prev, item]);
            setSelectedContent(prev => ({...prev, [`${item.id}`]: val}));
        } else {
            delete selectedContent[item.id];
            setContentItems(prev => prev.filter(cur => cur.id !== item.id));
        }
    };

    const addScenario = (val: boolean, item: IScenario) => {
        if (val) {
            setScenarioItems(prev => [...prev, item]);
            setSelectedScenarios(prev => ({...prev, [`${item.id}`]: val}));
        } else {
            delete selectedScenarios[item.id];
            setScenarioItems(prev => prev.filter(cur => cur.id !== item.id));
        }
    };

    const addInPlaylist = async () => {
        const newPlaylistItems: IPlaylistItem[] = [];
        for (const content of contentItems) {
            newPlaylistItems.push({
                id: uuidv4(),
                name: content.name,
                content: {
                    [`${content.id}`]: {
                        id: content.id,
                        content_id: content.id,
                        content: content.name,
                        path: content.path,
                        condition_types: [],
                        conditions: {},
                        is_end: true,
                        is_start: true,
                        next: null,
                        nodes: {},
                        content_type: content.type,
                        duration: content.duration,
                        blackout_duration: 300
                    }
                },
                content_id: content.id,
                preview: content.preview,
                display_type: "required",
                is_interruptible: false,
                duration: content.duration ? content.duration : 5,
                blackout_duration: 300,
                type: content.type,
                triggers: {
                    cv: [],
                    datetime: [],
                    eye_contact: null,
                    gesture: null
                }
            });
        }

        for (const scenario of scenarioItems) {
            const scenarioItem = await getScenario(scenario.id);

            if (!scenarioItem) {
                continue;
            }

            newPlaylistItems.push(scenarioItem);
        }

        addPlaylistItem(newPlaylistItems);
        setContentItems([]);
        setScenarioItems([]);
        setSelectedContent({})
        setShowModal(false);
    };

    return (
        <div className={"addPlaylistItems"}>
            <div className="switchControlPanel">
                <div className={classnames({ "active": menuSelect === "content" })} onClick={() => setMenuSelect("content")}>
                    <span>
                        {t("content.many")}
                    </span>
                    {menuSelect === "content"
                        ? <IconArrowUp color={"blue"} />
                        : <IconArrowDown />
                    }
                </div>
                <div className={classnames({ "active": menuSelect === "scenario" })} onClick={() => setMenuSelect("scenario")}>
                    <span>
                        {t("scenario.many")}
                    </span>
                    {menuSelect === "scenario"
                        ? <IconArrowUp color={"blue"} />
                        : <IconArrowDown />
                    }
                </div>
            </div>
            <div className={"searchContainer"}>
                <SearchInput
                    placeholder={t("placeholder.search")}
                    iconEnd={<IconSearch color={"soft"} />}
                    onSearch={(val) => setSearchText(val)}
                />
            </div>
            <div className={"addPlaylistContentContainer"}>
                {menuSelect === "content"
                    ? contentList.data.map(item =>
                        <label className={"add-item-container"} key={item.id} htmlFor={`checkbox-${item.id}`}>
                            <div className={"preview-container"}>
                                {item.preview.length
                                    ? <ContentContainer url={item.preview} contentType={"preview"} />
                                    : <IconVideoFile size={40} color={"black"} />
                                }
                            </div>
                            <div className={"name-container"}>
                                <div>
                                    {item.name}
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${item.id}`}
                                        checked={selectedContent[item.id]}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => addContent(e.target.checked, item)}
                                    />
                                </div>
                            </div>
                        </label>
                    )
                    : scenarioList.data.map(item =>
                        <label className={"add-item-container"} key={item.id} htmlFor={`checkbox-${item.id}`}>
                            <div className={"preview-container"}>
                                {item.preview.length
                                    ? <ContentContainer url={item.preview} contentType={"preview"} />
                                    : <IconScenarioFile size={40} color={"black"} />
                                }
                            </div>
                            <div className={"name-container"}>
                                <div>
                                    {item.name}
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${item.id}`}
                                        checked={selectedScenarios[item.id]}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => addScenario(e.target.checked, item)}
                                    />
                                </div>
                            </div>
                        </label>
                    )
                }
                <div className={"show-more"}>
                    {menuSelect === "content"
                        ? contentList.data.length < contentList.count && <span onClick={() => fetchPlaylistItemsHelper(page + 1)}>{t("create_playlist.add_playlist_item.show_more")}</span>
                        : scenarioList.data.length < scenarioList.count && <span onClick={() => fetchPlaylistItemsHelper(page + 1)}>{t("create_playlist.add_playlist_item.show_more")}</span>
                    }
                </div>
            </div>
            <div className={"footer-container"}>
                <div className={"selectionStats"}>
                    <span>Выбрано контента: {contentItems.length}</span>
                    <span>Выбрано сценариев: {scenarioItems.length}</span>
                </div>
                <div className={"buttons-container"}>
                    <Button onClick={() => setShowModal(false)} variant={"secondary"}>{t("button.cancel")}</Button>
                    <Button onClick={addInPlaylist} variant={"primary"}>{t("button.add")}</Button>
                </div>
            </div>
        </div>
    );
};

export default AddPlaylistItems;