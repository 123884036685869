import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { defaultFilters, defaultState } from "../consts";
import { ITerminalStore } from "../../types/terminals";
import { 
    bindPlaylistToTerminalApi, 
    getAllTerminalApi, 
    unbindPlaylistToTerminalApi, 
    updatePlaylistToTerminalApi,
} from "../../api/terminal-api";


export const useTerminalStore = create<ITerminalStore>()(
    immer(
        (set, get) => ({
            terminalList: defaultState,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.terminalList = defaultState;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchTerminals: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getAllTerminalApi(filters).then((data) => {
                    set((state) => {
                        state.terminalList = { ...state.terminalList, ...data };
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            bindPlaylist: async (data) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await bindPlaylistToTerminalApi(data).then(() => {
                    get().fetchTerminals(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            unbindPlaylist: async (terminal_ids) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await unbindPlaylistToTerminalApi(terminal_ids).then(() => {
                    get().fetchTerminals(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            updatePlaylist: async (terminal_id) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await updatePlaylistToTerminalApi(terminal_id).then(() => {
                    get().fetchTerminals(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            }
        })
    )
);
