import { shallow } from "zustand/shallow";

import { useContentStore as useContentStoreZus } from "./useContentStore";
import { IContentStore } from "../types/contentList";

import { useScenarioStore as useScenarioStoreZus } from "./useScenarioStore";
import { IScenarioStore } from "../types/scenarioList";

import { useCreateScenarioStore as useCreateScenarioStoreZus } from "./useCreateScenarioStore";
import { ICreateScenarioStore } from "../types/createScenario";

import { usePlaylistStore as usePlaylistStoreZus } from "./usePlaylistStore";
import { IPlaylistStore } from "../types/playlist";

import { useCreatePlaylistStore as useCreatePlaylistStoreZus } from "./useCreatePlaylistStore";
import { ICreatePlaylistStore } from "../types/createPlaylist";

import { useAnalyticStore as useAnalyticStoreZus } from "./useAnalyticStore";
import { IAnaliticStore } from "../types/analytics";

import { useTerminalStore as useTerminalStoreZus } from "./useTerminalStore";
import { ITerminalStore } from "../types/terminals";

import { useTerminalSetupStore as useTerminalSetupStoreZus } from "./useTerminalSetupStore";
import { ITerminalSetupStore } from "../types/terminalSetup";

import { useUserStore as useUserStoreZus } from "./useUserStore";
import { IUserStore } from "../types/user";

import { useTerminalPoolStore as useTerminalPoolStoreZus } from "./useTerminalPoolStore";
import { ITerminalPoolStore } from "../types/terminalPool";

import { useTerminalPollSetupStore as useTerminalPollSetupStoreZus } from "./useTerminalPoolSetupStore";
import { ITerminalPoolSetupStore } from "../types/terminalPoolSetup";


const useContentStore: <T>(selector: (store: IContentStore) => T) =>
    T = (selector) => useContentStoreZus(selector, shallow);

const useScenarioStore: <T>(selector: (store: IScenarioStore) => T) =>
    T = (selector) => useScenarioStoreZus(selector, shallow);

const useCreateScenarioStore: <T>(selector: (store: ICreateScenarioStore) => T) =>
    T = (selector) => useCreateScenarioStoreZus(selector, shallow);

const usePlaylistStore: <T>(selector: (store: IPlaylistStore) => T) =>
    T = (selector) => usePlaylistStoreZus(selector, shallow);

const useCreatePlaylistStore: <T>(selector: (store: ICreatePlaylistStore) => T) =>
    T = (selector) => useCreatePlaylistStoreZus(selector, shallow);

const useAnalyticStore: <T>(selector: (store: IAnaliticStore) => T) =>
    T = (selector) => useAnalyticStoreZus(selector, shallow);

const useTerminalStore: <T>(selector: (store: ITerminalStore) => T) =>
    T = (selector) => useTerminalStoreZus(selector, shallow);

const useTerminalSetupStore: <T>(selector: (store: ITerminalSetupStore) => T) =>
    T = (selector) => useTerminalSetupStoreZus(selector, shallow);

const useUserStore: <T>(selector: (store: IUserStore) => T) =>
    T = (selector) => useUserStoreZus(selector, shallow);

const useTerminalPoolStore: <T>(selector: (store: ITerminalPoolStore) => T) =>
    T = (selector) => useTerminalPoolStoreZus(selector, shallow);

const useTerminalPollSetupStore: <T>(selector: (store: ITerminalPoolSetupStore) => T) =>
    T = (selector) => useTerminalPollSetupStoreZus(selector, shallow);

export {
    useContentStore,
    useScenarioStore,
    useCreateScenarioStore,
    usePlaylistStore,
    useCreatePlaylistStore,
    useAnalyticStore,
    useTerminalStore,
    useUserStore,
    useTerminalSetupStore,
    useTerminalPoolStore,
    useTerminalPollSetupStore,
};