import React, { useEffect, useState } from "react";
import { Button, Select, SearchInput, IconSearch, Input, useNotification, ModalConfirm } from "../../../components/UI";
import styles from "./styles.module.scss";
import { useTranslation } from "i18nano";
import { ITerminalForPoolSetup } from "../../../types/terminalPoolSetup"
import { useTerminalPollSetupStore, useTerminalPoolStore } from "../../../stores";


interface ITerminalsTypeOptions {
    id: string;
    name: string;
}

interface IProps {
    terminalPoolId?: string;
    terminalPoolName?: string;
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const TerminalPoolSetup: React.FC<IProps> = ({ terminalPoolId, terminalPoolName, setIsShow }) => {
    const t = useTranslation();

    if (!terminalPoolName) {
        terminalPoolName = "";
    }
    
    const { fetchTerminalsForPoolSetup, clearStore, selectTerminal, terminalList, error } = useTerminalPollSetupStore(state => state);
    const {createTerminalPool, updateTerminalPool} = useTerminalPoolStore(state => state);
    const { addNotific } = useNotification();

    const perPage: number = 100;
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [terminalsType, setTerminalsType] = useState("inPoolTerminals");
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    
    const [poolName, setPoolName] = useState(terminalPoolName);
    const [addedTerminals, setAddedTerminals] = useState<Record<string, boolean>>({});
    const [deletedTerminals, setDeletedTerminals] = useState<Record<string, boolean>>({});
    const [terminalsInPoolStatusInitialState, setTerminalsInPoolStatusInitialState] = useState<Record<string, boolean>>({});

    const fetchTerminalsHelper = (page: number) => {
        setPage(page);

        fetchTerminalsForPoolSetup(
            {
                page: page,
                perPage: perPage,
                filters: { 
                    name: searchText,
                    terminal_pool_id: terminalPoolId,
                    only_free_terminals: terminalsType === "freeTerminals" ? true : false,
                    current_pool_terminals: terminalsType === "inPoolTerminals" ? true : false,
                }
            }
        );
    }

    const updateTerminalPoolHelper = () => {
        if (!poolName) {
            addNotific({
                title: t("terminal_pool_page.setup_model.name_required_notific.title"),
                body: t("terminal_pool_page.setup_model.name_required_notific.body"),
                type: "danger"
            });
            return
        }

        const addTerminalIds: Array<string> = [];
        const deleteTerminaIds: Array<string> = [];
        
        for (const key in addedTerminals) {
            addTerminalIds.push(key);
        }
        for (const key in deletedTerminals) {
            deleteTerminaIds.push(key);
        }
        
        if (terminalPoolId) {
            updateTerminalPool(
                {
                    id: terminalPoolId,
                    name: poolName,
                    add_terminal_ids: addTerminalIds,
                    delete_termina_ids: deleteTerminaIds
                }
            );
        } else {
            createTerminalPool(
                {
                    name: poolName,
                    add_terminal_ids: addTerminalIds,
                    delete_termina_ids: deleteTerminaIds
                }
            );
            setIsShow(false);
        }

    }

    const isTerminalPoolDataChange = () => {
        return (
            Object.keys(addedTerminals).length 
            || Object.keys(deletedTerminals).length 
            || poolName != terminalPoolName
        )
    }

    const closeTerminalPoolSetupHelper = () => {
        if (isTerminalPoolDataChange()) {
            setShowCancelConfirm(true);
        } else {
            setIsShow(false);            
        }
    }

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("terminal_pool.genitive") }),
                type: "danger"
            });
        }
    }, [error]);

    useEffect(() => {
        clearStore();
        fetchTerminalsHelper(1);
    }, [searchText, terminalsType]);
    

    const onInPoolChange = (terminal: ITerminalForPoolSetup) => {
        const terminalInPool = isInPoolChecked(terminal);
        
        if (!(terminal.id in terminalsInPoolStatusInitialState)) {
            if (!terminalInPool) {
                setAddedTerminals(prev => ({...prev, [`${terminal.id}`]: !terminalInPool}));
            } else {
                setDeletedTerminals(prev => ({...prev, [`${terminal.id}`]: !terminalInPool}));
            }
            
            setTerminalsInPoolStatusInitialState(prev => ({...prev, [`${terminal.id}`]: terminalInPool}));
        } else {
            if (terminal.id in addedTerminals){
                delete addedTerminals[terminal.id];
            }

            if (terminal.id in deletedTerminals){
                delete deletedTerminals[terminal.id];
            }

            delete terminalsInPoolStatusInitialState[terminal.id];
        }

        selectTerminal(
            { 
                selectState: !terminalInPool, 
                selectedData: terminal.id 
            }
        );
    };

    const isInPoolChecked = (terminal: ITerminalForPoolSetup) => {
        return (
            (terminal.in_pool && !(terminal.id in deletedTerminals))
            || addedTerminals[terminal.id]
        )
    }

    const opt: ITerminalsTypeOptions[] = [
        {
            id: "all",
            name: t("terminal_pool_page.setup_model.terminal_types.all")
        },
        {
            id: "inPoolTerminals",
            name: t("terminal_pool_page.setup_model.terminal_types.in_pool")
        },
        {
            id: "freeTerminals",
            name: t("terminal_pool_page.setup_model.terminal_types.free")
        }
    ];
    
    return (
        <div className={styles.root}>
            <div className={styles.general_settigns}>
                <h3 className={styles.titles_style}>{t("terminal_pool_page.setup_model.name_title")}</h3>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: 500 }}>
                        <Input type="text" onChange={setPoolName} value={poolName} placeholder={t("placeholder.text", { name: t("table_head.name") })}/>
                    </div>
                </div>
            </div>
            <div>
                <h3 className={styles.titles_style}>{t("terminal_pool_page.setup_model.select_terminals_name")}</h3>
                <div className={styles.search_container}>
                    <SearchInput
                        placeholder={t("placeholder.search")}
                        iconEnd={<IconSearch color={"soft"} />}
                        onSearch={(val) => setSearchText(val)}
                    />
                    {
                        terminalPoolId ?
                            <div className={styles.dropdown}>
                                <Select options={opt} value={terminalsType} setValue={setTerminalsType} />
                            </div> 
                        : null
                    }
                </div>
                <div className={styles.terminal_container}>
                    {terminalList.data.map(item =>
                        <label className={styles.terminal_item} key={item.id} htmlFor={`checkbox-${item.id}`}>
                            <div className={styles.name_container}>
                                <div className={styles.name_style}>
                                    {item.name}
                                </div>
                                <div className={styles.terminal_checkbox}>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${item.id}`}
                                        name="select-terminal-checkbox"
                                        checked={isInPoolChecked(item)}
                                        onChange={() => onInPoolChange(item)}
                                    />
                                </div>
                            </div>
                        </label>
                    )}
                    <div className={styles.show_more}>
                        {terminalList.data.length < terminalList.count ? <span onClick={() => fetchTerminalsHelper(page+1)}>{t("terminal_pool_page.setup_model.show_more")}</span> : null}
                    </div>
                </div>
            </div>
            <div className={styles.footer_container}>
                <div className={styles.selection_stats}>
                    {terminalPoolId ? <span>Удалено терминалов: {Object.keys(deletedTerminals).length }</span> : null}
                    <span>Добавлено терминалов: {Object.keys(addedTerminals).length }</span>
                </div>
                <div className={styles.button_container}>
                    <Button onClick={closeTerminalPoolSetupHelper} variant={"secondary"}>{t("button.cancel")}</Button>
                    <Button onClick={updateTerminalPoolHelper} variant={isTerminalPoolDataChange() ? "primary" : "disabled"}>{t("button.save")}</Button>
                </div>
            </div>
            <ModalConfirm isShow={showCancelConfirm} setIsShow={setShowCancelConfirm} onConfirm={() => setIsShow(false)} onCancel={() => { }}>
                {t("terminal_pool_page.setup_model.cancel_changes_confirm.body")}
            </ModalConfirm>
        </div>
    );
};

export default TerminalPoolSetup;