export const defaultState = {
    data: [],
    per_page: 10,
    page: 1,
    count: 0
};

export const defaultFilters = {
    page: 1,
    perPage: localStorage.getItem("perPage") ? Number(localStorage.getItem("perPage")) : 10,
    filters: { name: "" },
    orderBy: "name",
    orderDesk: false
};

export const defaultFilterState = [{ id: "0", name: "All" }];