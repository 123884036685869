import React, { useEffect, useState } from "react";
import "./AddContent.scss";
import { useContentStore } from "../../../../stores/";
import AddContentItem from "./AddContentItem";
import { useTranslation } from "i18nano";
import { SearchInput, IconSearch } from "../../../../components/UI";

const AddContent = () => {
    const t = useTranslation();

    const perPage: number = 100;
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const { contentList, fetchContentForPlaylist, clearStore } = useContentStore(state => state);

    useEffect(() => {
        clearStore();
        fetchPlaylistItemsHelper(1)
    }, [searchText]);

    const fetchPlaylistItemsHelper = (page: number) => {
        setPage(page);

        fetchContentForPlaylist(
            {
                page: page,
                perPage: perPage,
                filters: { name: searchText },
                orderBy: "name",
                orderDesk: false
            }
        );
    }

    return (
        <div>
            <div className={"searchContainer"}>
                <SearchInput
                    placeholder={t("placeholder.search")}
                    iconEnd={<IconSearch color={"soft"} />}
                    onSearch={(val) => setSearchText(val)}
                />
            </div>
            <ul className={"addContentContainer"}>
                {contentList.data.map(content =>
                    <AddContentItem content={content} key={content.id} />
                )}
            </ul>
            <div className={"show-more"}>
                {contentList.data.length < contentList.count && <span onClick={() => fetchPlaylistItemsHelper(page + 1)}>{t("create_playlist.add_playlist_item.show_more")}</span>}
            </div>
        </div>
    );
};

export default AddContent;