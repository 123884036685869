import React, { useEffect, useState } from "react";
import { useTranslation } from "i18nano";
import "./ContentPage.scss";
import ContentItem from "./ContentItem";
import { useContentStore } from "../../stores";
import { Pagination, useNotification, Loader, Button, Modal, ModalConfirm } from "../../components/UI";
import ControlPanel from "../../components/ControlPanel";
import { IFilters } from "../../types/default";
import LoadContent from "./LoadContent";
import PageContainer from "../../components/PageContainer";
import SortPanelItem from "../../components/SortPanelContainer/SortPanelItem";
import SortPanelContainer from "../../components/SortPanelContainer";


const ContentPage = () => {
    const { contentList, loading, error, fetchContent, deleteContent, selectContent, clearStore } = useContentStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const [checkedAll, setCheckedAll] = useState(false);
    const [showLoadModal, setShowLoadModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [filters, setFilters] = useState<IFilters>({
        page: 1,
        perPage: localStorage.getItem("perPage") ? Number(localStorage.getItem("perPage")) : 10,
        filters: { name: "" },
        orderBy: "name",
        orderDesk: false
    });

    useEffect(() => {
        return () => clearStore();
    }, []);

    useEffect(() => {
        fetchContent(filters);
        setCheckedAll(false);
    }, [filters]);

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("content.genitive") }),
                type: "danger"
            });
        }
    }, [error]);

    const selectAll = (checkedState: boolean) => {
        selectContent({ selectState: checkedState, selectedData: "all" });
        setCheckedAll(checkedState);
    };

    const filtersContent = (order_by: string) => {
        if (order_by === filters.orderBy) {
            setFilters(prev => ({ ...prev, orderDesk: !filters.orderDesk }));
        }
        setFilters(prev => ({ ...prev, orderBy: order_by }));
    };

    const onDelete = () => {
        const deleteList: Array<string> = [];
        for (const file of contentList.data) {
            if (file.isChecked) {
                deleteList.push(file.id);
            }
        }
        deleteContent(deleteList);
        selectContent({ selectState: false, selectedData: "all" });
        setCheckedAll(false);
    };

    return (
        <PageContainer>
            <ControlPanel
                onSelectAll={selectAll}
                onDelete={() => setShowConfirm(true)}
                onSearch={(val) => setFilters(prev => ({ ...prev, filters: { name: val } }))}
                righrPanel={<Button variant={"primary"} onClick={() => setShowLoadModal(true)}>{`${t("button.upload")} ${t("file.one")}`}</Button>}
                selectedLenght={contentList.data.filter(item => item.isChecked).length}
            />
            <SortPanelContainer>
                <SortPanelItem
                    type={"checkbox"}
                    onChange={(e) => selectAll(e.target.checked)}
                    checked={checkedAll}
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.name")}
                    onClick={() => filtersContent("name")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.size")}
                    onClick={() => filtersContent("size")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.resolution")}
                    onClick={() => filtersContent("resolution")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.actions")}
                />
            </SortPanelContainer>
            {loading
                ? <h1 style={{
                    "textAlign": "center",
                    "fontSize": "50px",
                    "marginTop": "100px"
                }}><Loader /></h1>
                : <>
                    {contentList.data.length
                        ? <>
                            <div className={"content-table"}>
                                {contentList.data.map((content) =>
                                    <ContentItem content={content} key={content.id} />
                                )}
                            </div>
                            <Pagination
                                perPage={filters.perPage}
                                setPerPage={(val) => setFilters(prev => ({ ...prev, perPage: val }))}
                                page={filters.page}
                                setPage={(val) => setFilters(prev => ({ ...prev, page: val }))}
                                count={contentList.count}
                            />
                        </>
                        : <h1 style={{
                            "textAlign": "center",
                            "fontSize": "50px",
                            "marginTop": "100px"
                        }}>{t("missing.content")}</h1>
                    }
                </>
            }
            <Modal isShow={showLoadModal} setIsShow={setShowLoadModal} title={t("upload_file.title")}>
                <LoadContent setIsShow={setShowLoadModal} />
            </Modal>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => onDelete()} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: `${t("elements")} (${contentList.data.filter(item => item.isChecked).length})` })}</p>
            </ModalConfirm>
        </PageContainer>
    );
};

export default ContentPage;