import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Button, ModalConfirm } from "../../../../components/UI";
import { useTerminalPoolStore } from "../../../../stores";
import { ITerminalPool } from "../../../../types/terminalPool";
import { TPlaylistModal } from "../TerminalPoolItem";
import styles from "./styles.module.scss";

interface IProps {
    terminalPool: ITerminalPool;
    showModalHandler: (mode: TPlaylistModal) => void;
}

const TerminalPoolSettingsModal: React.FC<IProps> = ({ terminalPool, showModalHandler }) => {
    const t = useTranslation();
    const user_role = localStorage.getItem("user_role");
    const is_edit_available = user_role === "admin" || user_role === "organization_admin";
    const { changeTerminalPoolPlaylist } = useTerminalPoolStore(state => state);

    const [showConfirm, setShowConfirm] = useState(false);

    const unbindPlaylistHandler = () => {
        if (terminalPool.current_playlist) {
            changeTerminalPoolPlaylist({terminal_pool_id: terminalPool.id});
        }
    };

    const updatePlaylistHandler = () => {
        if (terminalPool.current_playlist) {
            changeTerminalPoolPlaylist(
                {
                    terminal_pool_id: terminalPool.id,
                    playlist_id: terminalPool.current_playlist.playlist_id
                }
            );
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.message}>
                {t("terminal_pool_page.terminal_settings_modal.message", { name: terminalPool.name })}
            </div>
            <div className={is_edit_available ? styles.buttons_container : styles.without_edit_buttons_container}>
                { 
                    is_edit_available ?  
                        <Button variant={"primary-empty"} onClick={() => showModalHandler("edit")}>{t("terminal_pool_page.terminal_settings_modal.controls.edit_terminal_pool")}</Button>
                    : null
                }
                <Button variant={"primary-empty"} onClick={() => showModalHandler("bind")}>{t("terminal_pool_page.terminal_settings_modal.controls.change_playlist")}</Button>
                <Button variant={terminalPool.current_playlist ? "primary-empty" : "disabled"} onClick={() => window.location.href = `/playlists/edit/${terminalPool.current_playlist!.playlist_id}`}>{t("terminal_pool_page.terminal_settings_modal.controls.view_playlist")}</Button>
                <Button variant={terminalPool.current_playlist ? "primary-empty" : "disabled"} onClick={() => updatePlaylistHandler()}>{t("terminal_pool_page.terminal_settings_modal.controls.update_playlist")}</Button>
                <Button variant={terminalPool.current_playlist ? "danger-empty" : "disabled"} onClick={() => setShowConfirm(true)}>{t("terminal_pool_page.terminal_settings_modal.controls.unbind_playlist")}</Button>
            </div>
            {terminalPool.current_playlist
                ? <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={unbindPlaylistHandler} onCancel={() => { }}>
                    {t("terminal_pool_page.unbind_confirm.message", { name: terminalPool.current_playlist.playlist_name })}
                </ModalConfirm>
                : null
            }
        </div>
    );
};

export default TerminalPoolSettingsModal;