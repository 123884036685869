import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { defaultState } from "../consts";
import { ITerminalPoolSetupStore } from "../../types/terminalPoolSetup";
import {
    getTerminalsForPollSetupApi,
} from "../../api/terminal-api";


export const useTerminalPollSetupStore = create<ITerminalPoolSetupStore>()(
    immer(
        (set, get) => ({
            terminalList: defaultState,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.terminalList = defaultState;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchTerminalsForPoolSetup: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getTerminalsForPollSetupApi(filters).then((data) => {
                    set((state) => {
                        if (data) {
                            state.terminalList.data.push(...data.data);
                            state.terminalList.page = data.page;
                            state.terminalList.per_page = data.per_page;
                            state.terminalList.count = data.count;
                        }
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            selectTerminal: (selectStatus) => {
                set((state) => {
                    state.terminalList.data = [...state.terminalList.data.map(terminal => {
                        if (selectStatus.selectedData === "all" || selectStatus.selectedData === terminal.id) {
                            return { ...terminal, in_pool: selectStatus.selectState };
                        }
                        return terminal;
                    })];
                });
            }
        })
    )
);
